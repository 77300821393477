import React from 'react';
import styled from "styled-components";

export const Seal = () => {
    return (
        <Wrap>
            <svg width="192" height="192" viewBox="0 0 192 192">

            
<path fill-rule="evenodd" clip-rule="evenodd" d="M96 1.97938C44.0738 1.97938 1.97938 44.0738 1.97938 96C1.97938 147.926 44.0738 190.021 96 190.021C147.926 190.021 190.021 147.926 190.021 96C190.021 44.0738 147.926 1.97938 96 1.97938ZM0 96C0 42.9807 42.9807 0 96 0C149.019 0 192 42.9807 192 96C192 149.019 149.019 192 96 192C42.9807 192 0 149.019 0 96Z"/>
<path d="M117.15 19.7832L117.174 19.696C117.904 17.4512 119.982 16.3566 122.188 17.0705C123.799 17.594 124.997 18.7917 124.616 20.6637L123.212 20.2116C123.37 19.1566 122.831 18.5062 121.855 18.1889C120.379 17.713 119.173 18.53 118.634 20.1878L118.61 20.275C118.086 21.8852 118.475 23.2337 120.062 23.7493C121.474 24.2093 122.331 23.543 122.712 22.5119L120.816 21.9011L121.157 20.8461L124.433 21.909L124.211 22.6071C123.537 24.6932 121.815 25.534 119.689 24.8439C117.261 24.0428 116.436 22.0042 117.15 19.7832Z"/>
<path d="M128.138 19.3865L129.503 19.9655L126.401 27.263L125.036 26.684L128.138 19.3865Z"/>
<path d="M133.249 23.0036L131.289 21.9883L131.82 20.9572L137.065 23.662L136.533 24.6932L134.574 23.6779L131.471 29.6904L130.154 29.0082L133.249 23.0036Z"/>
<path d="M139.278 24.9469L140.507 25.756L138.659 28.5719L141.634 30.5231L143.483 27.7073L144.72 28.5243L140.365 35.1475L139.127 34.3305L140.976 31.5147L138 29.5634L136.152 32.3793L134.922 31.5702L139.278 24.9469Z"/>
<path d="M144.102 34.1719L147.251 30.4042L148.394 31.356L145.316 35.0365C144.387 36.1549 144.355 36.9085 145.26 37.662C146.133 38.3918 146.91 38.3759 147.958 37.1147L150.972 33.5056L152.115 34.4575L149.021 38.1617C147.696 39.7561 146.101 39.9147 144.49 38.5663C142.967 37.2971 142.8 35.7345 144.102 34.1719Z"/>
<path d="M154.392 36.6151L156.36 38.6537C157.494 39.8276 157.717 40.954 156.677 41.9534L156.645 41.9851C156.082 42.5324 155.384 42.7069 154.567 42.2548C155.146 43.1908 155.075 43.9681 154.321 44.6979L154.281 44.7296C153.218 45.7529 152.004 45.5704 150.798 44.3172L148.68 42.1279L154.392 36.6151ZM151.441 43.4526C152.123 44.1664 152.758 44.1823 153.353 43.6112L153.385 43.5795C153.972 43.0084 153.956 42.3976 153.202 41.6203L152.282 40.6605L150.457 42.4214L151.441 43.4526ZM153.837 40.8508C154.511 41.5489 155.035 41.5806 155.614 41.0174L155.646 40.9857C156.185 40.4622 156.122 39.9307 155.463 39.2486L154.63 38.384L152.988 39.9704L153.837 40.8508Z"/>
<path d="M162.525 45.7765L164.017 47.8468C165.056 49.2825 165.096 50.6864 163.747 51.6621L163.715 51.6859C162.359 52.6615 161.049 52.1142 160.058 50.742L159.407 49.8456L156.963 51.6066L156.099 50.4088L162.525 45.7765ZM160.891 50.0915C161.462 50.8847 162.152 51.0275 162.866 50.5199L162.906 50.4961C163.668 49.9488 163.644 49.2825 163.096 48.521L162.47 47.6485L160.272 49.2269L160.891 50.0915Z"/>
<path d="M167.15 52.4474L168.483 54.7477C169.356 56.2548 169.348 57.6667 167.943 58.4758L167.904 58.4996C166.825 59.1183 165.896 58.7613 165.182 58.0395L163.334 61.6803L162.532 60.3001L164.27 56.9052L163.802 56.0882L161.025 57.6905L160.287 56.4134L167.15 52.4474ZM165.301 56.5879C165.793 57.4446 166.42 57.6826 167.15 57.2622L167.19 57.2384C167.975 56.7862 167.959 56.1358 167.491 55.3267L166.896 54.3035L164.706 55.5647L165.301 56.5879Z"/>
<path d="M167.475 61.1569L167.555 61.1252C169.681 60.2051 171.918 61.0459 172.854 63.1955C173.799 65.3609 172.91 67.5264 170.776 68.4465L170.696 68.4782C168.57 69.3983 166.341 68.6448 165.381 66.4317C164.413 64.2187 165.381 62.0691 167.475 61.1569ZM170.078 67.0901L170.157 67.0584C171.649 66.408 172.402 65.0912 171.775 63.6714C171.157 62.2515 169.744 61.8629 168.173 62.5371L168.094 62.5688C166.507 63.2589 165.904 64.6708 166.476 65.9955C167.079 67.3598 168.499 67.7723 170.078 67.0901Z"/>
<path d="M175.267 69.5807L175.957 71.8889C176.75 74.5382 175.624 76.2674 173.41 76.9337L173.315 76.9654C171.101 77.6238 169.15 76.8068 168.348 74.1416L167.666 71.8572L175.267 69.5807ZM169.451 73.7609C169.959 75.4584 171.181 75.9898 172.863 75.4821L172.95 75.4584C174.584 74.9666 175.354 73.9275 174.823 72.1427L174.577 71.3257L169.205 72.928L169.451 73.7609Z"/>
<path d="M172.918 79.3768L177.758 78.5122L178.019 79.9717L173.291 80.8125C171.863 81.0663 171.355 81.6295 171.561 82.7876C171.76 83.914 172.363 84.3899 173.981 84.1043L178.615 83.2794L178.876 84.7389L174.124 85.5876C172.085 85.9525 170.76 85.0482 170.395 82.9859C170.038 81.0346 170.903 79.7337 172.918 79.3768Z"/>
<path d="M175.211 87.7293L175.298 87.7214C177.678 87.5707 179.44 89.1016 179.583 91.3067C179.702 93.1231 178.876 94.5827 177.036 94.8761L176.941 93.3849C177.98 93.1549 178.488 92.5282 178.416 91.394C178.329 89.9979 177.187 89.165 175.417 89.2761L175.33 89.284C173.545 89.3951 172.553 90.3152 172.648 91.7747C172.72 92.8693 173.323 93.607 174.449 93.6943L174.544 95.13C172.624 95.0189 171.577 93.726 171.458 91.8461C171.284 89.3633 172.775 87.88 175.211 87.7293Z"/>
<path d="M178.417 98.8579L178.504 96.6528L179.67 96.7004L179.448 102.594L178.282 102.546L178.369 100.341L171.609 100.079L171.665 98.5962L178.417 98.8579Z"/>
<path d="M178.734 108.892L178.289 111.256C177.774 113.969 175.981 114.992 173.712 114.563L173.616 114.548C171.347 114.119 169.983 112.501 170.506 109.765L170.951 107.425L178.734 108.892ZM171.649 109.939C171.315 111.684 172.164 112.707 173.886 113.033L173.973 113.048C175.655 113.366 176.806 112.795 177.155 110.962L177.314 110.121L171.807 109.082L171.649 109.939Z"/>
<path d="M176.822 117.958L175.409 122.71L174.291 122.377L175.282 119.037L173.196 118.418L172.402 121.099L171.339 120.782L172.133 118.101L169.911 117.443L168.864 120.957L167.745 120.624L169.213 115.69L176.822 117.958Z"/>
<path d="M169.578 122.9L169.038 124.217C168.261 124.011 167.523 124.05 167.015 125.296C166.682 126.105 166.904 126.866 167.578 127.144C168.253 127.421 168.649 127.183 169.332 126.073C170.284 124.415 171.156 123.804 172.537 124.36C173.735 124.851 174.219 126.247 173.6 127.77C172.957 129.349 171.847 129.999 170.387 129.571L170.895 128.318C171.72 128.5 172.228 128.191 172.569 127.35C172.91 126.517 172.68 125.922 172.116 125.692C171.521 125.446 171.125 125.573 170.419 126.771C169.482 128.397 168.626 129.087 167.15 128.492C165.88 127.977 165.317 126.485 165.983 124.867C166.809 122.868 168.149 122.432 169.578 122.9Z"/>
<path d="M171.118 133.061L170.451 134.386L163.374 130.832L164.04 129.508L171.118 133.061Z"/>
<path d="M165.794 134.767L165.865 134.814C167.872 136.052 168.444 138.336 167.23 140.303C166.341 141.747 164.889 142.619 163.167 141.802L163.945 140.549C164.937 140.954 165.69 140.589 166.238 139.708C167.055 138.392 166.547 137.019 165.064 136.107L164.992 136.059C163.548 135.171 162.144 135.227 161.271 136.639C160.494 137.9 160.938 138.891 161.842 139.51L162.89 137.812L163.834 138.392L162.025 141.326L161.406 140.946C159.542 139.795 159.129 137.924 160.303 136.02C161.628 133.862 163.81 133.545 165.794 134.767Z"/>
<path d="M163.572 145.626L162.477 147.006L155.916 146.054L160.407 149.623L159.574 150.67L153.369 145.737L154.321 144.539L161.303 145.522L156.543 141.739L157.376 140.692L163.572 145.626Z"/>
<path d="M157.415 153.026L153.948 156.564L153.115 155.747L155.551 153.264L153.996 151.741L152.044 153.732L151.251 152.955L153.202 150.964L151.552 149.346L148.982 151.963L148.148 151.146L151.75 147.474L157.415 153.026Z"/>
<path d="M151.846 158.515L149.807 160.22C148.474 161.339 147.085 161.577 146.046 160.339L146.014 160.308C145.221 159.356 145.403 158.372 145.998 157.547L142.095 156.35L143.317 155.326L146.966 156.453L147.688 155.85L145.633 153.391L146.76 152.447L151.846 158.515ZM147.45 157.412C146.696 158.047 146.562 158.705 147.109 159.348L147.141 159.38C147.728 160.078 148.355 159.951 149.077 159.348L149.989 158.586L148.371 156.651L147.45 157.412Z"/>
<path d="M140.262 167.145L138.612 168.145L132.265 162.727L133.574 161.934L135.129 163.282L137.715 161.712L137.231 159.713L138.414 158.991L140.262 167.145ZM137.985 162.806L135.986 164.02L138.89 166.566L137.985 162.806Z"/>
<path d="M134.082 170.667L131.773 171.754C130.17 172.515 128.782 172.293 128.068 170.794L128.052 170.754C127.338 169.239 128.115 168.057 129.646 167.328L130.646 166.852L129.361 164.123L130.694 163.496L134.082 170.667ZM130.138 168.279C129.258 168.7 128.988 169.35 129.361 170.144L129.377 170.183C129.781 171.032 130.44 171.127 131.289 170.731L132.265 170.27L131.106 167.827L130.138 168.279Z"/>
<path d="M126.711 173.983L124.307 174.839C122.641 175.434 121.276 175.093 120.713 173.523L120.697 173.483C120.134 171.904 121.022 170.802 122.617 170.231L123.657 169.858L122.641 167.018L124.029 166.519L126.711 173.983ZM123.014 171.222C122.094 171.555 121.768 172.174 122.062 172.999L122.078 173.039C122.395 173.927 123.038 174.086 123.926 173.768L124.942 173.404L124.029 170.857L123.014 171.222Z"/>
<path d="M119.046 176.545L117.618 176.91L115.952 170.374L112.675 171.206L112.382 170.072L117.087 168.874L119.046 176.545Z"/>
<path d="M111.851 178.21L110.391 178.472L108.986 170.675L110.446 170.413L111.851 178.21Z"/>
<path d="M107.281 174.982L107.289 175.069C107.511 177.441 106.027 179.25 103.829 179.448C102.02 179.614 100.537 178.837 100.188 176.997L101.679 176.862C101.941 177.893 102.576 178.385 103.71 178.274C105.099 178.147 105.908 176.981 105.741 175.212L105.733 175.125C105.567 173.348 104.623 172.38 103.163 172.515C102.068 172.618 101.346 173.237 101.298 174.371L99.8623 174.506C99.9178 172.587 101.179 171.5 103.052 171.325C105.527 171.095 107.051 172.547 107.281 174.982Z"/>
<path d="M95.8077 179.67L93.8797 179.638L91.4043 171.667L92.9356 171.69L93.5306 173.665L96.5614 173.721L97.2199 171.77L98.6084 171.793L95.8077 179.67ZM96.1885 174.784L93.848 174.744L94.9508 178.448L96.1885 174.784Z"/>
<path d="M88.0641 178.091L90.2539 178.329L90.1269 179.488L84.2637 178.853L84.3906 177.695L86.5804 177.933L87.3103 171.214L88.7861 171.373L88.0641 178.091Z"/>
<path d="M81.828 178.417L80.3682 178.147L81.8042 170.35L83.2641 170.62L81.828 178.417Z"/>
<path d="M78.6939 173.792L78.6701 173.88C78.0433 176.109 75.9805 177.314 73.7193 176.688C71.4422 176.053 70.3235 173.999 70.9583 171.754L70.9821 171.667C71.6088 169.438 73.6003 168.184 75.917 168.835C78.2496 169.485 79.3048 171.595 78.6939 173.792ZM72.4498 172.063L72.426 172.15C71.9897 173.721 72.5371 175.125 74.0287 175.545C75.5203 175.966 76.7183 175.109 77.1785 173.467L77.2023 173.38C77.6704 171.714 76.9564 170.35 75.5679 169.961C74.1319 169.565 72.91 170.413 72.4498 172.063Z"/>
<path d="M67.2132 174.506L65.5867 173.824L64.714 167.248L62.5004 172.539L61.2627 172.023L64.3173 164.71L65.7295 165.297L66.6816 172.277L69.0222 166.669L70.2599 167.185L67.2132 174.506Z"/>
<path d="M57.8119 165.099C57.4231 165.765 56.5663 166.011 55.8681 165.606C55.1619 165.194 54.9715 164.337 55.3603 163.663C55.757 162.981 56.598 162.743 57.3041 163.155C58.0103 163.568 58.2165 164.425 57.8119 165.099Z"/>
<path d="M52.8043 158.119L52.114 159.047C51.9553 158.793 51.7887 158.555 51.4158 158.277C50.8366 157.849 50.2416 157.873 49.6545 158.65L46.3698 163.044L45.1797 162.156L48.4802 157.73C49.5117 156.35 50.7732 156.31 51.9395 157.19C52.4711 157.579 52.6773 157.865 52.8043 158.119Z"/>
<path d="M46.1086 156.659L42.816 160.3L41.7131 159.308L44.9343 155.747C45.9102 154.668 45.9658 153.914 45.093 153.129C44.2441 152.368 43.4745 152.352 42.3796 153.573L39.2298 157.056L38.127 156.056L41.364 152.479C42.7525 150.94 44.3472 150.845 45.9023 152.249C47.3701 153.581 47.4812 155.144 46.1086 156.659Z"/>
<path d="M40.325 150.536L39.3808 149.481C39.9124 148.878 40.1901 148.196 39.3015 147.188C38.7144 146.538 37.9368 146.395 37.3973 146.887C36.8499 147.371 36.8896 147.831 37.5877 148.933C38.6588 150.52 38.8255 151.575 37.7226 152.558C36.7547 153.423 35.2869 153.248 34.192 152.011C33.0574 150.742 32.9543 149.465 33.9857 148.33L34.8902 149.338C34.3665 149.996 34.4221 150.591 35.0251 151.265C35.6201 151.932 36.2548 151.995 36.7071 151.59C37.1831 151.162 37.2466 150.75 36.4849 149.592C35.4376 148.037 35.1917 146.958 36.3738 145.903C37.3973 144.991 38.9762 145.142 40.1425 146.443C41.5627 148.061 41.3643 149.457 40.325 150.536Z"/>
<path d="M30.6134 145.689L31.9225 147.466L30.9863 148.156L27.4795 143.412L28.4157 142.722L29.7248 144.499L35.1676 140.485L36.0482 141.683L30.6134 145.689Z"/>
<path d="M26.1625 141.318L25.3691 140.065L32.0813 135.845L32.8747 137.098L26.1625 141.318Z"/>
<path d="M23.6947 137.36L22.8855 135.79L26.4478 130.198L21.3542 132.823L20.7354 131.633L27.7808 128L28.479 129.357L24.7182 135.314L30.1213 132.53L30.7402 133.72L23.6947 137.36Z"/>
<path d="M18.0773 125.185L17.2442 122.662C16.6968 121.012 16.9903 119.632 18.5216 119.124L18.5613 119.109C19.7355 118.72 20.5844 119.259 21.1319 120.108L23.6787 116.919L24.1786 118.434L21.7904 121.409L22.084 122.305L25.1227 121.298L25.5829 122.694L18.0773 125.185ZM20.7273 121.504C20.4178 120.568 19.8545 120.203 19.0532 120.473L19.0135 120.489C18.1487 120.774 18.0376 121.409 18.3312 122.305L18.7041 123.432L21.1002 122.638L20.7273 121.504Z"/>
<path d="M15.4915 116.911L15.1821 115.468L18.4826 114.77L17.7447 111.288L14.4442 111.986L14.1348 110.534L21.8942 108.892L22.2037 110.344L18.9031 111.042L19.641 114.524L22.9415 113.826L23.251 115.269L15.4915 116.911Z"/>
<path d="M17.6729 107.377L17.5856 107.385C15.2768 107.583 13.4203 106.084 13.214 103.744C13.0077 101.396 14.531 99.6273 16.8477 99.4211L16.935 99.4132C19.2438 99.2149 21.1163 100.635 21.3225 103.03C21.5447 105.442 19.95 107.179 17.6729 107.377ZM17.0858 100.928L16.9985 100.936C15.372 101.079 14.2533 102.086 14.3882 103.633C14.5231 105.18 15.7449 105.997 17.4428 105.846L17.5301 105.838C19.2518 105.687 20.2752 104.537 20.1483 103.102C20.0134 101.618 18.7995 100.777 17.0858 100.928Z"/>
<path d="M13 95.3441L13.0476 93.4166L21.0372 90.9814L21.0054 92.5123L19.0299 93.0993L18.9505 96.1294L20.9023 96.7957L20.8705 98.1838L13 95.3441ZM17.8874 95.7566L17.9429 93.4166L14.2377 94.4954L17.8874 95.7566Z"/>
<path d="M13.3408 88.2131L13.6582 85.8255C14.0231 83.089 15.7686 81.9705 18.0536 82.2799L18.1488 82.2958C20.4338 82.6051 21.8858 84.1519 21.5129 86.9122L21.1955 89.276L13.3408 88.2131ZM20.3545 86.7932C20.5925 85.0323 19.696 84.0487 17.9584 83.8187L17.8711 83.8029C16.1812 83.5728 15.0546 84.2074 14.8007 86.0556L14.6896 86.9043L20.2434 87.6499L20.3545 86.7932Z"/>
<path d="M14.7773 79.0516L15.9436 74.2368L17.0782 74.5065L16.261 77.8856L18.3715 78.3932L19.03 75.6805L20.109 75.9422L19.4505 78.655L21.7037 79.2023L22.5686 75.6329L23.7031 75.9026L22.4971 80.8998L14.7773 79.0516Z"/>
<path d="M21.7518 73.7292L22.2199 72.3887C23.0053 72.5553 23.7432 72.468 24.1796 71.2068C24.4652 70.3819 24.2113 69.6363 23.521 69.3904C22.8308 69.1524 22.45 69.4063 21.8232 70.5485C20.9584 72.2539 20.1173 72.9122 18.713 72.4284C17.4912 72.0001 16.9279 70.6357 17.4753 69.0731C18.0386 67.4629 19.1018 66.757 20.5934 67.106L20.1491 68.383C19.316 68.2482 18.8241 68.5813 18.5226 69.438C18.2291 70.2867 18.4829 70.8658 19.0621 71.0641C19.673 71.2782 20.0618 71.1275 20.7045 69.8981C21.5613 68.2244 22.3785 67.4867 23.8781 68.0102C25.1793 68.4624 25.814 69.9139 25.2348 71.5717C24.5207 73.6103 23.2037 74.11 21.7518 73.7292Z"/>
<path d="M25.1149 62.6243C24.4167 62.2753 24.1152 61.4425 24.4802 60.7206C24.8451 59.983 25.6861 59.7371 26.3843 60.0861C27.0905 60.4351 27.392 61.2521 27.0191 61.9898C26.662 62.7116 25.821 62.9813 25.1149 62.6243Z"/>
<path d="M31.7401 57.1591L30.7722 56.5324C31.0181 56.3579 31.2403 56.1755 31.4942 55.7868C31.8829 55.184 31.8195 54.5891 31.0022 54.0576L26.3926 51.0752L27.2018 49.8298L31.8353 52.8282C33.2793 53.7641 33.4063 55.0174 32.6129 56.2469C32.2558 56.7863 31.994 57.0163 31.7401 57.1591Z"/>
<path d="M30.0732 45.856L31.2633 44.3409L39.118 47.1648L38.1739 48.3704L36.238 47.6565L34.3735 50.0362L35.5398 51.7336L34.6829 52.8283L30.0732 45.856ZM33.7388 49.1002L35.1828 47.2599L31.5648 45.9115L33.7388 49.1002Z"/>
<path d="M34.6201 40.3192L35.8578 39.066L42.2844 40.7079L38.1984 36.6943L39.1425 35.7345L44.7915 41.2869L43.7204 42.3816L36.8892 40.6682L41.2212 44.9278L40.2771 45.8876L34.6201 40.3192Z"/>
<path d="M44.5775 37.4082L41.4912 33.5929L42.6496 32.6569L45.6724 36.3929C46.5849 37.5192 47.3227 37.6937 48.2351 36.9561C49.1237 36.2342 49.2507 35.4807 48.2193 34.2036L45.2599 30.5469L46.4182 29.611L49.449 33.3628C50.7502 34.973 50.5995 36.5674 48.973 37.8841C47.4179 39.1295 45.8549 38.9946 44.5775 37.4082Z"/>
<path d="M50.3926 26.8586L52.0111 25.8115L58.5091 31.0546L57.2238 31.8875L55.629 30.5787L53.0822 32.2206L53.6217 34.2037L52.4554 34.9572L50.3926 26.8586ZM52.7966 31.1339L54.7642 29.8648L51.789 27.4059L52.7966 31.1339Z"/>
<path d="M56.4785 23.1623L58.8587 21.9645C60.4138 21.1793 61.8181 21.2665 62.548 22.7102L62.5718 22.7498C63.1272 23.8603 62.7226 24.7725 61.953 25.4467L65.4836 27.5011L64.0555 28.2229L60.7629 26.2955L59.9219 26.7159L61.3659 29.5793L60.0488 30.2456L56.4785 23.1623ZM60.509 25.2405C61.3897 24.7963 61.6674 24.1855 61.2865 23.432L61.2627 23.3923C60.8502 22.5832 60.2075 22.5594 59.3665 22.9798L58.3033 23.5113L59.4379 25.7719L60.509 25.2405Z"/>
<path d="M67.3718 23.0591L63.3096 19.8149L64.8329 19.228L67.6812 21.6472L68.1255 17.9588L69.4743 17.4432L68.7761 22.5356L70.0059 25.7323L68.6174 26.2637L67.3718 23.0591Z"/>
<path d="M76.4166 23.3288L78.6302 19.5849C79.1856 18.6409 79.4791 17.8557 79.3046 17.118C79.138 16.3962 78.6222 16.0313 77.8764 16.2058C77.1068 16.3803 76.726 16.9356 76.845 18.054L75.528 18.3554C75.2423 16.65 76.0358 15.4443 77.6702 15.0715C79.2728 14.7066 80.4312 15.4047 80.7406 16.769C80.9707 17.7764 80.574 18.7837 79.8599 19.8784L78.527 21.9486L81.8117 21.1951L82.0656 22.3056L76.4801 23.5826L76.4166 23.3288Z"/>
<path d="M83.1521 18.276L83.1045 17.8794C82.8109 15.4919 83.9455 13.8658 85.929 13.6199C87.9204 13.374 89.4041 14.667 89.6977 17.0307L89.7453 17.4273C90.0388 19.7911 88.9915 21.4568 86.9366 21.7106C84.9293 21.9565 83.4457 20.6477 83.1521 18.276ZM88.2775 17.6098L88.2299 17.2211C88.0236 15.5474 87.254 14.6194 86.0798 14.7621C84.8897 14.9129 84.3581 15.9361 84.5802 17.6891L84.6278 18.0857C84.85 19.8704 85.6434 20.6874 86.8176 20.5367C88.0236 20.386 88.4917 19.3945 88.2775 17.6098Z"/>
<path d="M91.5 20.8461L94.4197 17.6177C95.1576 16.8007 95.594 16.0947 95.5781 15.3412C95.5622 14.5956 95.1258 14.1435 94.3642 14.1593C93.5787 14.1752 93.0868 14.6432 92.9837 15.7695L91.6269 15.7933C91.6904 14.0641 92.706 13.0409 94.38 13.0012C96.0224 12.9616 97.0221 13.8817 97.0538 15.2777C97.0776 16.3089 96.4905 17.2211 95.5702 18.1491L93.8485 19.9101L97.2204 19.8307L97.2442 20.973L91.5079 21.1078L91.5 20.8461Z"/>
<path d="M100.782 14.6828L99.0449 15.7219L99.1163 14.4289L101.028 13.2312L102.345 13.3105L101.885 21.2188L100.402 21.1315L100.782 14.6828Z"/>
<path d="M108.153 18.5141C108.28 17.7526 108.994 17.2132 109.787 17.356C110.596 17.4908 111.08 18.2285 110.946 18.99C110.811 19.7673 110.12 20.2908 109.311 20.1481C108.518 20.0132 108.018 19.2835 108.153 18.5141Z"/>



            </svg>
        </Wrap>
    );
}

const Wrap = styled.div`
    width: 192px;
    height: 192px;
    position: absolute;
    right: -1em;
    top: calc(-8em + 40px);
    animation: rotation 60s infinite linear;
    svg {
        fill: #FFFFFF;
    }
    @keyframes rotation {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(359deg);
        }
    }
    @media (min-width: 768px) {
        right: 2em;
    }
`;




